<template>
  <v-card>
    <v-card-title>入金(編集)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-form class="ma-6">
        <v-row align="center">
          <v-col
            cols="12"
            md="2"
          >
            <label for="areaID">入金ID<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-col
              cols="12"
              md="4"
            >
              <label for="depo_id">{{ Form.depo_id }}</label>
            </v-col>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            md="2"
          >
            <label
              class="text-right"
            >取引先<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="$v.Form.cust_abbr.$model"
              :error-messages="custAbbrErrors"
              readonly
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
          >
            <Customer
              :id="Form.cust_id"
              :selected-customer="selectedCustomer"
              @add_cuslist="getaddCusList"
            ></Customer>
          </v-col>
        </v-row>
        <v-row v-if="Form.cust_id">
          <v-col
            cols="12"
            md="2"
          >
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            {{ Form.cust_id }}:{{ Form.cust_name }}
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            {{ Form.cust_name_eng }}
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            md="2"
          >
            <label for="depo_date">入金日<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              :nudge-right="40"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="$v.Form.depo_date.$model"
                  :error-messages="DateErrors"
                  outlined
                  dense
                  placeholder="入金日"
                  hide-details="auto"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="Form.depo_date"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            md="2"
          >
            <label>通貨:</label>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="Form.ccy_id"
              :items="currencies"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            md="2"
          >
            <label for="depo_amount">入金金額<span style="color: red">*</span></label>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <PriceInputFixedComponent
              v-model="Form.depo_amount"
              :fixed-num="2"
              :outlined="true"
              :error-messages="AmountErrors"
              :prefix="ccy_mark"
            ></PriceInputFixedComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <label for="depo_note">備考</label>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-textarea
              v-model="$v.Form.depo_note.$model"
              :error-messages="noteErrors"
              name="depo_note"
              :counter="255"
              outlined
              dense
              placeholder="備考内容など記入"
              hide-details="auto"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col
            offset-md="5"
            cols="12"
          >
            <v-btn
              color="primary"
              :loading="submitStatus"
              @click="submit(Form)"
            >
              Submit
            </v-btn>
            <v-btn
              class="mx-2"
              outlined
              @click="cancelClick"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-card>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import {
  required, minValue, maxLength,
} from 'vuelidate/lib/validators'
import Customer from '@/components/Customer.vue'

import PriceInputFixedComponent from '@/components/PriceInputFixedComponent.vue'

export default {
  components: {
    PriceInputFixedComponent,
    Customer,
  },
  data: () => ({
    submitStatus: false,
    menu: false,
    ccy_mark: '',
    selectedCustomer: [],
    Form: {
      cust_id: '',
      cust_abbr: '',
      cust_name: '',
      cust_name_eng: '',
      depo_id: '',
      depo_date: '',
      ccy_id: 0,
      depo_amount: 0,
      depo_note: '',
    },
    OriginalForm: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  validations: {
    Form: {
      cust_abbr: {
        required,
      },
      depo_date: {
        required,
      },
      depo_amount: {
        required,
        minValue: minValue(0.01),
      },
      depo_note: {
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapState('depositStore', ['selectedDeposit']),
    ...mapState('currencyStore', ['currencies']),

    AmountErrors() {
      const errors = []
      if (!this.$v.Form.depo_amount.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.depo_amount.required && errors.push('必須項目')

      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.depo_amount.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.depo_amount.minValue && errors.push('0以外にしてください')

      return errors
    },

    DateErrors() {
      const errors = []
      if (!this.$v.Form.depo_date.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.depo_date.required && errors.push('必須項目')

      return errors
    },

    AccountIdErrors() {
      const errors = []
      if (!this.$v.Form.depo_account_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.depo_account_name.required && errors.push('必須項目')

      return errors
    },

    custAbbrErrors() {
      const errors = []

      if (!this.$v.Form.cust_abbr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cust_abbr.required && errors.push('必須項目')

      return errors
    },

    noteErrors() {
      const errors = []
      if (!this.$v.Form.depo_note.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.depo_note.maxLength && errors.push('255文字以内にしてください。')

      return errors
    },
  },
  watch: {
    // 'Form.cust_id': {
    //   deep: true,
    //   handler(newValue) {
    //     this.Form.cust_abbr = ''
    //     if (newValue > 0) {
    //       for (let i = 0; i < this.customerIds.length; i += 1) {
    //         if (this.customerIds[i].value === newValue) {
    //           this.Form.cust_abbr = this.customerIds[i].text
    //           break
    //         }
    //       }
    //     }
    //   },
    // },

    'Form.ccy_id': {
      handler(val) {
        if (val) {
          const currency = this.currencies.find(c => c.value === val)
          this.ccy_mark = currency.mark

          console.log('currency:', this.currency)
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('depositStore', ['loadDeposit', 'editDeposit']),
    ...mapActions('currencyStore', ['loadCurrencyList']),

    getBankAccount(Account) {
      const selectAccount = Account
      if (selectAccount.length === 1) {
        this.Form.depo_account_id = selectAccount[0].id
        this.Form.depo_account_name = selectAccount[0].name
      } else {
        this.Form.depo_account_id = ''
        this.Form.depo_account_name = ''
      }
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadDeposit(this.$route.params.id),
        this.loadCurrencyList(),
      ]).then(() => {
        this.Form = this.$deepCopy(this.selectedDeposit)
        console.log('Form', this.Form)
        this.OriginalForm = JSON.stringify(this.AreaEditForm)
      })
        .catch(error => {
          console.log('loadData', error)
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },

    cancelClick() {
      if (this.OriginalForm !== JSON.stringify(this.Form)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    jumpToListPage() {
      this.$router.push({
        path: '/deposit-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({
        path: this.$route.path, query,
      })
    },

    getaddCusList(selectCustomer) {
      this.selectedCustomer = selectCustomer
      if (selectCustomer.length === 1) {
        this.Form.cust_id = selectCustomer[0].cust_id
        this.Form.cust_abbr = selectCustomer[0].cust_abbr
        this.Form.cust_name = selectCustomer[0].cust_name
        this.Form.cust_name_eng = selectCustomer[0].cust_name_eng
      } else {
        this.Form.cust_id = ''
        this.Form.cust_abbr = ''
        this.Form.cust_name = ''
        this.Form.cust_name_eng = ''
      }
    },

    submit(Form) {
      this.$v.$touch()
      console.log(this.$v)
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.editDeposit(Form)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            console.log('error', error)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
              console.log('error', this.apierror)
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
